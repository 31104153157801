import { IncomingMessage } from 'http'
import cookies from 'next-cookies'
import { MyApp } from '@go/types'

const authVarify = (req?: MyApp.Request & IncomingMessage) => {
  let _isLogin = false
  if (req && req.session) {
    _isLogin = !!req.session?.credential
  } else if (typeof window !== 'undefined') {
    _isLogin = 'refreshToken' in cookies({ req })
  }

  return {
    isLogin: _isLogin,
  }
}

export { authVarify }

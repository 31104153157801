import Page from '@go/components/pages/Top'
import { MyApp } from '@go/types'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { withLang } from '../hoc/withLang'
import { authVarify } from '../modules/req_auth'

const Index: MyApp.NextPage = () => {
  const { pathname } = useRouter()
  return (
    <>
      <NextSeo
        title='クリエイターのための機材シェアリングコミュニティ'
        description='Piicは機材をお手頃価格で借りられ、自分の機材を貸し出す事も出来るクリエイターのためのシェアリングコミュニティです。'
        openGraph={{
          title: 'クリエイターのための機材シェアリングコミュニティ',
          description:
            'Piicは機材をお手頃価格で借りられ、自分の機材を貸し出す事も出来るクリエイターのためのシェアリングコミュニティです。',
          url: `${process.env.FRONTEND_HOST}/${pathname}`,
        }}
      />
      <Page />
    </>
  )
}

Index.getInitialProps = async ({ req }) => {
  return authVarify(req)
}

export default withLang(Index)

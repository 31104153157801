import React from 'react'
import { I18nProvider } from '@lingui/react'
import { MyApp } from '@go/types'
// eslint-disable-next-line import/no-unresolved
import { I18nProviderProps } from '@lingui/react/I18nProvider'

type BaseProps = Pick<I18nProviderProps, 'catalogs' | 'language'>

export function withLang<P = {}, IP = P>(Page: MyApp.NextPage<P, IP>, defaultLang = 'ja') {
  return class WithLang extends React.Component<P> {
    static async getInitialProps(ctx: MyApp.NextPageContext) {
      const { language: lang, languages }: Pick<Navigator, 'language' | 'languages'> =
        typeof window !== 'undefined' ? window.navigator : { languages: [], language: defaultLang }
      let language =
        ctx.query.lang instanceof Array ? ctx.query.lang[0] : ctx.query.lang || languages[0] || lang

      if (language !== 'en') {
        language = defaultLang
      }
      const [props, catalog] = await Promise.all([
        Page.getInitialProps ? Page.getInitialProps(ctx) : {},
        import(`@lingui/loader!../locale/${language}/messages.json`).then((m) => m.default),
      ])

      return {
        ...props,
        language,
        catalogs: {
          [language]: catalog,
        },
      }
    }

    render() {
      const { language, catalogs, ...restProps } = this.props as P & BaseProps

      return (
        <I18nProvider language={language} catalogs={catalogs}>
          <Page {...((restProps as unknown) as P)} />
        </I18nProvider>
      )
    }
  }
}
